import { BiDownArrow, BiUpArrow } from "react-icons/bi";
import Moment from "react-moment";
import { BANK_SLIP_ID, PROFILE_IMAGE_ID } from "../../utils/constants";
import { GetLatestProfilePic } from "../../utils/utilityFunctions";
import { MdPreview } from "react-icons/md";
import { IoOpenOutline } from "react-icons/io5";
import useCheckImageExistence from "../../components/common/useCheckImageExistence";
import { getImageUrl } from "../../components/common/imageUtils";

export const VerifiedApplicants = {
  name: "Verified Applicant",

  emrColumns(navigate, openModalEMR, handleVerifyPermit) {
    return [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "expander", // It needs an ID
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <BiUpArrow /> : <BiDownArrow />}
          </span>
        ),
      },
      {
        Header: "Name",
        id: "name",
        accessor: (c) => {
          const imageUrl = getImageUrl(GetLatestProfilePic(c));
        return(  <div className="flex items-center justify-start gap-2">
            <button
              onClick={() => navigate(`/dashboard/applicant/document/${c?.id}`)}
              type="button"
              className="relative"
              data-model-target="#defaultModal"
              data-modal-toggle="#defaultModal"
            >
              <div
                // onClick={() => navigate("/dashboard/applicant/profile/" + c.id)}
                className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600"
              >
                <span className="font-medium text-gray-600 dark:text-gray-300">
                  {/* {c.first_name.substring(0, 1) + c.father_name.substring(0, 1)} */}
                  <img
                    className="w-10 h-10 p-1 object-cover rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
                    src={
                      imageUrl
                    }
                    alt="Bordered avatar"
                  />
                </span>
              </div>
            </button>
            {c?.expatriate?.first_name}{" "}
            {c?.expatriate?.father_name?.substring(0, 6)}
          </div>)
      },
      },
      {
        Header: "Applied date",
        accessor: (c) => (
          <Moment format="MMMM Do YYYY">{c?.expatriate?.created_at}</Moment>
        ),
      },
      {
        Header: "Assigned date",
        accessor: (c) => (
          <Moment format="MMMM Do YYYY">
            {c?.assigned_workpermit_reviewers[0]?.created_at}
          </Moment>
        ),
      },
      {
        Header: "Slip Number",
        accessor: (c) =>
          c?.expatriate_work_permit_documents
            .filter((i) => i.document_type?.id === BANK_SLIP_ID)
            .map((j) => j?.review_status)[0]
            ? "Verfied"
            : "Not Verified",
      },
      {
        Header: "EMR Number",
        accessor: (c) => c?.emr_number,
      },
      {
        Header: "Work Permit",
        accessor: (c) => (
          <button
            onClick={() => handleVerifyPermit(c)}
            type="button"
            disabled={c?.emr_number !== "" && c?.emr_number !== null}
            className="disabled:opacity-60 disabled:pointer-events-none disabled:cursor-not-allowed text-center border-2 rounded-md px-3 py-1 bg-primary text-white"
          >
            Verify Permit
          </button>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ cell }) => (
          <div>
            <button
              disabled={
                cell?.row?.original?.emr_number === null ||
                cell?.row?.original?.emr_number === ""
              }
              onClick={() =>
                openModalEMR({
                  letter: cell?.row?.original?.immigration_letter,
                  emr: cell?.row?.original?.emr_number,
                })
              }
              // onClick={() => handleGenerateURL(cell.row.original)}
              className={`${
                cell?.row?.original?.emr_number === null ||
                cell?.row?.original?.emr_number === ""
                  ? "opacity-60 pointer-events-none"
                  : ""
              } border-2 border-blue-400 text-blue-400 px-3 py-1 rounded-sm hover:bg-blue-400 hover:text-white`}
            >
              Generate
            </button>
          </div>
        ),
      },
    ];
  },

  emrColumnsRenewal(navigate, openModalEMR, handlePreview) {
    return [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "expander", // It needs an ID
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <BiUpArrow /> : <BiDownArrow />}
          </span>
        ),
      },
      {
        Header: "Name",
        id: "name",
        accessor: (c) => {
          const imageUrl = getImageUrl(GetLatestProfilePic(c));
         return(<div className="flex items-center justify-start gap-2">
             <button
              onClick={() => navigate(`/dashboard/applicant/document/${c?.id}`)}
              type="button"
              className="relative"
              data-model-target="#defaultModal"
              data-modal-toggle="#defaultModal"
            >
              <div
                // onClick={() => navigate("/dashboard/applicant/profile/" + c.id)}
                className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600"
              >
                <span className="font-medium text-gray-600 dark:text-gray-300">
                  {/* {c.first_name.substring(0, 1) + c.father_name.substring(0, 1)} */}
                  <img
                    className="w-10 h-10 p-1 object-cover rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
                    src={
                      imageUrl
                    }
                    alt="Bordered avatar"
                  />
                </span>
              </div>
            </button>
            {c?.expatriate?.first_name}{" "}
            {c?.expatriate?.father_name?.substring(0, 6)}
          </div>)
        },
      },
      {
        Header: "Applied date",
        accessor: (c) => (
          <Moment format="MMMM Do YYYY">{c?.expatriate?.created_at}</Moment>
        ),
      },
      {
        Header: "Assigned date",
        accessor: (c) => (
          <Moment format="MMMM Do YYYY">
            {c?.assigned_workpermit_reviewers &&
              c?.assigned_workpermit_reviewers[0]?.created_at}
          </Moment>
        ),
      },
      {
        Header: "Slip Number",
        accessor: (c) =>
          c?.expatriate_work_permit_documents &&
          c?.expatriate_work_permit_documents
            .filter((i) => i.document_type?.id === BANK_SLIP_ID)
            .map((j) => j?.review_status)[0]
            ? "Verfied"
            : "Not Verified",
      },
      {
        Header: "EMR Number",
        accessor: (c) => c?.emr_number,
      },
      // {
      //   Header: "Work Permit",
      //   accessor: (c) => (
      //     <button
      //       onClick={() => handleVerifyPermit(c)}
      //       type="button"
      //       disabled={c?.emr_number !== "" && c?.emr_number !== null}
      //       className="disabled:opacity-60 disabled:pointer-events-none disabled:cursor-not-allowed text-center border-2 rounded-md px-3 py-1 bg-primary text-white"
      //     >
      //       Verify Permit
      //     </button>
      //   ),
      // },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ cell }) => (
          <div>
            <button
              disabled={
                cell?.row?.original?.emr_number === null ||
                cell?.row?.original?.emr_number === ""
              }
              onClick={() =>
                openModalEMR({
                  emr: cell?.row?.original?.emr_number,
                  id: cell?.row?.original?.id,
                })
              }
              className={`${
                cell?.row?.original?.emr_number === null ||
                cell?.row?.original?.emr_number === ""
                  ? "opacity-60 pointer-events-none"
                  : ""
              } border-2 border-blue-400 text-blue-400 px-3 py-1 rounded-sm hover:bg-blue-400 hover:text-white`}
            >
              Update
            </button>
            <button
              disabled={
                cell?.row?.original?.emr_number === null ||
                cell?.row?.original?.emr_number === ""
              }
              type="button"
              onClick={() => handlePreview(cell?.row?.original?.id)}
              className={`${
                cell?.row?.original?.emr_number === null ||
                cell?.row?.original?.emr_number === ""
                  ? "opacity-60 pointer-events-none"
                  : ""
              } px-4 py-1 rounded-md`}
            >
              <IoOpenOutline size={20} />
            </button>
          </div>
        ),
      },
    ];
  },
};
