import { BiUpArrow, BiDownArrow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import { BANK_SLIP_ID, PROFILE_IMAGE_ID } from "../../utils/constants";
import Tooltip from "../../components/Tooltip";
import StatusTags from "../../components/utilities/StatusTags";
import { BsCheckCircle } from 'react-icons/bs';
import {  BsFillPersonCheckFill } from "react-icons/bs";
import { FiAlertCircle } from "react-icons/fi";
import { GetLatestProfilePic } from "../../utils/utilityFunctions";
import { getImageUrl } from "../../components/common/imageUtils";

export const ApplicantColumns = {
  /**
   * @description DOCUMENT Checker
   * @param {*} navigate
   * @param {*} handleReviewStatus
   * @access document checker
   * @returns
   */
  documentHandlerApplicantColumn(navigate, handleReviewStatus) {
    return [
      {
        Header: "Name",
        id: "name",
        accessor: (c) => {
          const imageUrl = getImageUrl( GetLatestProfilePic(c?.expatriate_work_permit));
         return( 
         <div className="flex justify-start gap-2">
            <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
              <img
                className="w-10 h-10 p-1 object-cover rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
                src={
                  {imageUrl}
                }
                alt="Bordered avatar"
              />
            </div>
            <p className="self-center">
              {c?.expatriate_work_permit?.expatriate?.first_name}{" "}
              {c?.expatriate_work_permit?.expatriate?.father_name}
            </p>
          </div>
        )},
      },
      {
        Header: "Applied date",
        accessor: (c) => (
          <Moment format="MMMM Do YYYY">
            {c?.expatriate_work_permit?.updated_at}
          </Moment>
        ),
      },
      {
        Header: "Doc. type No",
        accessor: (c) => (
          <p className="flex items-center justify-center text-center">
            {/* <BiFile className="fill-green" /> */}
            {
              c?.expatriate_work_permit?.expatriate_work_permit_documents
                ?.length
            }
          </p>
        ),
      },
      {
        Header: "Financial Doc",
        accessor: (c) => (
          <span
            className={`${
              handleReviewStatus(c) === "Verifed"
                ? "bg-green/70 text-white"
                : handleReviewStatus(c) === "Not Verified"
                ? "bg-red"
                : ""
            } px-2 py-0.5 rounded text-xs bg-blue-100`}
          >
            {handleReviewStatus(c)}
          </span>
        ),
      },
      {
        Header: "Document Status",
        accessor: (c) => (
          <span
            className={`${
              c?.expatriate_work_permit?.document_checker_status === true
                ? "bg-green text-white"
                : c?.expatriate_work_permit?.document_checker_status === false
                ? "bg-red10 text-fuchsia-50 "
                : "bg-yellow30 text-white"
            } px-2 py-0.5 rounded text-xs bg-blue-100`}
          >
            {c?.expatriate_work_permit?.document_checker_status
              ? "Approved"
              : c?.expatriate_work_permit?.document_checker_status === false
              ? "Rejected"
              : "Not Approved"}
          </span>
        ),
      },
      {
        Header: "Nationality",
        accessor: (c) => (
          <span className="bg-blue-200 text-blue-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-blue-900 dark:text-blue-200">
            {c?.expatriate_work_permit?.expatriate?.nationality?.name}
          </span>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ cell }) => (
          <button
            onClick={() =>
              navigate(
                "/dashboard/applicant/document/" +
                  cell?.row?.original?.expatriate_work_permit?.id
              )
            }
            className="border-primary rounded-md border-2 px-4 py-1 font-normal text-blue-600 hover:bg-primary hover:text-white"
          >
            Verify
          </button>
        ),
      },
    ];
  },

  /**
   * @description FINANCE Handler
   * @param {*} handleReviewStatus
   * @access Fiancne handler
   * @param {*} openModal
   */
  financeHandlerApplicantColumn(handleReviewStatus, openModal) {
    return [
       {
        Header: "Name",
        accessor: (c) =>{
          const imageUrl = getImageUrl( GetLatestProfilePic(c?.expatriate_work_permit));
         return( 
          <div className="flex items-center justify-start gap-2">
            {/* <Tooltip tooltipsText="Profile"> */}
            <button
              // onClick={() => navigate("/dashboard/applicant/profile/"+ c.id)}
              type="button"
              className="relative"
              data-model-target="#defaultModal"
              data-modal-toggle="#defaultModal"
            >
              <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                <span className="font-medium text-gray-600 dark:text-gray-300">
                  <img
                    className="w-10 h-10 p-1 object-cover rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
                    src={
                      imageUrl
                    }
                    alt="Bordered avatar"
                  />
                </span>
              </div>
              {c?.expatriate_work_permits?.work_permit_black_list?.length >
                0 && (
                <div className="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-normal text-white bg-red border-2 border-white rounded-full -bottom-2 -right-2 dark:border-gray-100">
                  {c?.expatriate_work_permits?.work_permit_black_list}
                </div>
              )}
            </button>
            {/* </Tooltip> */}
            {c?.expatriate_work_permit?.expatriate?.first_name}{" "}
            {c?.expatriate_work_permit?.expatriate?.father_name?.substring(
              0,
              12
            )}
          </div>
        )},
      },
      {
        Header: "Applied date",
        accessor: (c) => (
          <Moment format="MMMM Do YYYY">
            {c?.expatriate_work_permit?.created_at}
          </Moment>
        ),
      },
      {
        Header: "Assigned date",
        accessor: (c) => (
          <Moment format="MMMM Do YYYY">
            {c?.expatriate_work_permit?.created_at}
          </Moment>
        ),
      },
      {
        Header: "Slip Number",
        accessor: (c) =>
          c?.expatriate_work_permit?.reference_number?.substring(0, 16),
      },
      {
        Header: "Organization",
        accessor: (c) => (
          <div className="text-center bg-gray-200 p-2 rounded-lg">
            @{c?.expatriate_work_permit?.organization?.name?.substring(0, 12)}
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: (c) => (
          <div
            className={`${
              handleReviewStatus(c) === "Verifed"
                ? "bg-green/70 text-white"
                : handleReviewStatus(c) === "Rejected"
                ? "bg-red"
                : "bg-blue-100"
            } px-3 py-2 rounded-md `}
          >
            {handleReviewStatus(c)}
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ cell }) => (
          <button
            onClick={() => openModal(cell)}
            className="border-2 border-blue-400 text-blue-400 px-3 py-1 rounded-sm hover:bg-blue-400 hover:text-white"
          >
            Verify
          </button>
        ),
      },
    ];
  },

  /**
   * @description Desk Handler
   * @access Desk handler
   * @param {*} navigate
   * @param {*} handleAssign
   * @param {*} loadingRows
   * @param {*} handlePopUp
   * @returns
   */
  deskHandlerApplicantColumn(navigate, handleAssign, loadingRows, handlePopUp) {
    return [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "expander", // It needs an ID
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <BiUpArrow /> : <BiDownArrow />}
          </span>
        ),
      },
      {
        Header: "Name",
        footer: (props) => props.column.id,
        id: "name",
        accessor: (c) => {
          const imageUrl = getImageUrl( c?.expatriate_work_permit_documents
            ?.filter(
              (i) => i.document_type?.id === PROFILE_IMAGE_ID
            )
            ?.map((i) => i?.files)[0]);
         return( 
          <div className="flex justify-start gap-2">
            <button
              // onClick={() => navigate("/dashboard/applicant/profile/"+ c.id)}
              type="button"
              className="relative"
              data-model-target="#defaultModal"
              data-modal-toggle="#defaultModal"
            >
              <div
                onClick={() =>
                  navigate("/dashboard/applicant/profile/" + c?.id)
                }
                className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600"
              >
                <span className="font-medium text-gray-600 dark:text-gray-300">
                  {/* {c.first_name.substring(0, 1) + c.father_name.substring(0, 1)} */}
                  <img
                    className="w-10 h-10 p-1 object-cover rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
                    src={imageUrl
                   
                     
                    }
                    alt={c?.expatriate?.first_name}
                  />
                </span>
              </div>
              {c?.work_permit_black_list?.length > 0 && (
                <div
                  onClick={handlePopUp(c)}
                  className="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-normal text-white bg-red border-2 border-white rounded-full -bottom-2 -right-2 dark:border-gray-100"
                >
                  {c?.work_permit_black_list}
                </div>
              )}
            </button>
            <p className="self-center">
              <p>
                {c?.expatriate?.first_name} {c?.expatriate?.father_name}
              </p>
              <a
                onClick={() =>
                  navigate(
                    "/dashboard/organization/profile/" + c?.organization?.id
                  )
                }
                className="transititext-primary flex items-center gap-1 text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                data-te-toggle="tooltip"
                title="Organization name"
              >
                <BsCheckCircle /> {c?.organization?.name?.substring(0, 12)}
              </a>
            </p>
          </div>
         
        )},
      },
      {
        Header: "Applied date",
        accessor: (c) => <Moment format="MMMM Do YYYY">{c?.updated_at}</Moment>,
      },
      {
        Header: "Martial Status",
        accessor: (c) => (
          <p className="text-center">{c?.expatriate?.marital?.name}</p>
        ),
      },
      {
        Header: "Financial Doc",
        accessor: (c) => (
          <div className="flex item-center">
            {/* console.log(checkVerify(c?.work_permit_finance_documents)); */}
            {c?.expatriate_work_permit_documents
              ?.filter((i) => i?.document_type?.id === BANK_SLIP_ID)
              .map((b) => b?.review_status)[0] ? (
              <StatusTags status="verified" />
            ) : (
              <StatusTags status="uploaded" />
            )}
          </div>
        ),
      },
      {
        Header: "Gender",
        accessor: (c) => c?.expatriate?.gender?.name,
      },
      {
        Header: "Nationality",
        accessor: (c) => (
          <span className="bg-blue-200 text-blue-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-blue-900 dark:text-blue-200">
            {c?.expatriate?.nationality?.name}
          </span>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ cell }) => (
          <button
            key={cell?.row.id}
            className="disabled:opacity-60 disabled:bg-blue-200 disabled:text-black disabled:cursor-not-allowed border-2 border-blue-400 text-blue-400 font-normal px-3 py-1 rounded-md hover:bg-blue-400 hover:text-white"
          >
            {loadingRows.includes(cell?.row?.original?.id) && (
              <svg
                aria-hidden="true"
                role="status"
                className="inline w-6 self-center h-4 mr-3 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
            )}
            {cell?.row?.original?.assigned_workpermit_reviewers?.length > 0 ? (
              <>
                <div
                  onClick={() =>
                    navigate(
                      "/dashboard/applicant/profile/" +
                        cell?.row?.original?.id
                    )
                  }
                  className="flex items-center gap-2"
                >
                  <BsFillPersonCheckFill className="text-green50 text-lg" />
                  <FiAlertCircle className="text-N80 text-lg" />
                </div>
              </>
            ) : !loadingRows.includes(cell?.row?.original?.id) ? (
              <button
                className={`disabled:opacity-60 
              ${
                loadingRows
                  ? "disabled:bg-blue-200 disabled:text-black disabled:cursor-not-allowed"
                  : "border-2 border-blue-400 text-blue-400 font-normal px-3 py-1 rounded-md hover:bg-blue-400 hover:text-white"
              }`}
                disabled={loadingRows.includes(
                  cell?.row?.original?.id
                )}
                onClick={() =>
                  handleAssign(cell?.row?.original?.id)
                }
              >
                {!loadingRows.includes(cell?.row?.original?.id)
                  ? "Assign"
                  : ""}
              </button>
            ) : (
              ""
            )}
          </button>
        ),
      },
    ];
  },

  deskHandlerRenewalApplicantColumn(
    navigate,
    handleAssign,
    loadingRows,
    handlePopUp
  ) {
    return [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "expander", // It needs an ID
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <BiUpArrow /> : <BiDownArrow />}
          </span>
        ),
      },
      {
        Header: "Name",
        footer: (props) => props.column.id,
        id: "name",
        accessor: (c) => {
          const imageUrl = getImageUrl( GetLatestProfilePic(
            c
          ));
         return(
          <div className="flex justify-start gap-2">
            <button
              // onClick={() => navigate("/dashboard/applicant/profile/"+ c.id)}
              type="button"
              className="relative"
              data-model-target="#defaultModal"
              data-modal-toggle="#defaultModal"
            >
              <div
                onClick={() => navigate("/dashboard/applicant/profile/" + c.id)}
                className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600"
              >
                <span className="font-medium text-gray-600 dark:text-gray-300">
                  <img
                    className="w-10 h-10 p-1 object-cover rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
                    src={  imageUrl }
                    alt={c?.expatriate?.first_name}
                  />
                </span>
              </div>
              {/* {c?.work_permit_black_list?.length >
                0 && (
                <div
                  onClick={handlePopUp(c)}
                  className="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-normal text-white bg-red border-2 border-white rounded-full -bottom-2 -right-2 dark:border-gray-100"
                >
                  {c?.expatriate_work_permits?.work_permit_black_list}
                </div>
              )} */}
            </button>
            <p className="self-center">
              <p>
                {c?.expatriate?.first_name} {c?.expatriate?.father_name}
              </p>
              <a
                onClick={() =>
                  navigate(
                    "/dashboard/organization/profile/" + c?.organization?.id
                  )
                }
                className="transititext-primary flex items-center gap-1 text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                data-te-toggle="tooltip"
                title="Organization name"
              >
                <BsCheckCircle /> {c?.organization?.name?.substring(0, 12)}
              </a>
            </p>
          </div>
        )},
      },
      {
        Header: "Applied date",
        accessor: (c) => <Moment format="MMMM Do YYYY">{c.created_at}</Moment>,
      },
      {
        Header: "Martial Status",
        accessor: (c) => (
          <p className="text-center">{c?.expatriate?.marital?.name}</p>
        ),
      },
      {
        Header: "Financial Doc",
        accessor: (c) => (
          <div className="flex item-center">
            {/* console.log(checkVerify(c?.work_permit_finance_documents)); */}
            {c?.expatriate_work_permit_documents
              ?.filter((i) => i?.document_type?.id === BANK_SLIP_ID)
              .map((b) => b?.review_status)[0] ? (
              <StatusTags status="verified" />
            ) : (
              <StatusTags status="uploaded" />
            )}
          </div>
        ),
      },
      {
        Header: "Gender",
        accessor: (c) => c?.expatriate?.gender?.name,
      },
      {
        Header: "Nationality",
        accessor: (c) => (
          <span className="bg-blue-200 text-blue-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-blue-900 dark:text-blue-200">
            {c?.expatriate?.nationality?.name}
          </span>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ cell }) => (
          <button
            key={cell?.row.id}
            className="disabled:opacity-60 disabled:bg-blue-200 disabled:text-black disabled:cursor-not-allowed border-2 border-blue-400 text-blue-400 font-normal px-3 py-1 rounded-md hover:bg-blue-400 hover:text-white"
          >
            {loadingRows.includes(cell?.row?.original?.id) && (
              <svg
                aria-hidden="true"
                role="status"
                className="inline w-6 self-center h-4 mr-3 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
            )}
            {cell?.row?.original?.assigned_workpermit_reviewers?.length > 0 ? (
              <>
                <div
                  onClick={() =>
                    navigate(
                      "/dashboard/applicant/profile/" + cell?.row?.original?.id
                    )
                  }
                  className="flex items-center gap-2"
                >
                  <BsFillPersonCheckFill className="text-green50 text-lg" />
                  <FiAlertCircle className="text-N80 text-lg" />
                </div>
              </>
            ) : !loadingRows.includes(cell?.row?.original?.id) ? (
              <button
                className={`disabled:opacity-60 
              ${
                loadingRows
                  ? "disabled:bg-blue-200 disabled:text-black disabled:cursor-not-allowed"
                  : "border-2 border-blue-400 text-blue-400 font-normal px-3 py-1 rounded-md hover:bg-blue-400 hover:text-white"
              }`}
                disabled={loadingRows.includes(cell?.row?.original?.id)}
                onClick={() => handleAssign(cell?.row?.original?.id)}
              >
                {!loadingRows.includes(cell?.row?.original?.id) ? "Assign" : ""}
              </button>
            ) : (
              ""
            )}
          </button>
        ),
      },
    ];
  },

  systemAdminApplicantColumn(navigate, handleAssign, loadingRows, handlePopUp) {
    return [
      {
        Header: "Name",
        footer: (props) => props.column.id,
        id: "name",
        accessor: (c) =>{
          const imageUrl = getImageUrl( c?.expatriate_work_permits[0]?.expatriate_work_permit_documents
            ?.filter(
              (i) => i.document_type?.id === PROFILE_IMAGE_ID
            )
            ?.map((i) => i?.files)[0]);
         return(
          <div className="flex justify-start gap-2">
            <button
              // onClick={() => navigate("/dashboard/applicant/profile/"+ c.id)}
              type="button"
              className="relative"
              data-model-target="#defaultModal"
              data-modal-toggle="#defaultModal"
            >
              <div
                onClick={() => navigate("/dashboard/applicant/profile/" + c.id)}
                className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600"
              >
                <span className="font-medium text-gray-600 dark:text-gray-300">
                  {/* {c.first_name.substring(0, 1) + c.father_name.substring(0, 1)} */}
                  <img
                    className="w-10 h-10 p-1 object-cover rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
                    src={imageUrl
                    }
                    alt="Bordered avatar"
                  />
                </span>
              </div>
              {c?.expatriate_work_permits?.work_permit_black_list?.length >
                0 && (
                <div
                  onClick={handlePopUp(c)}
                  className="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-normal text-white bg-red border-2 border-white rounded-full -bottom-2 -right-2 dark:border-gray-100"
                >
                  {c?.expatriate_work_permits?.work_permit_black_list}
                </div>
              )}
            </button>
            <p className="self-center">
              <p>
                {c?.first_name} {c?.father_name}
              </p>
              <a
                onClick={() =>
                  navigate(
                    "/dashboard/organization/profile/" +
                      c?.expatriate_work_permits[0]?.organization?.id
                  )
                }
                className="transititext-primary flex items-center gap-1 text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                data-te-toggle="tooltip"
                title="Organization name"
              >
                <BsCheckCircle />{" "}
                {c?.expatriate_work_permits[0]?.organization?.name?.substring(
                  0,
                  12
                )}
              </a>
            </p>
          </div>
        )},
      },
      {
        Header: "Applied date",
        accessor: (c) => <Moment format="MMMM Do YYYY">{c.created_at}</Moment>,
      },
      {
        Header: "Martial Status",
        accessor: (c) => <p className="text-center">{c?.marital?.name}</p>,
      },
      {
        Header: "Financial Doc",
        accessor: (c) => (
          <div className="flex item-center">
            {/* console.log(checkVerify(c?.work_permit_finance_documents)); */}
            {c?.expatriate_work_permits[0]?.expatriate_work_permit_documents
              ?.filter((i) => i?.document_type?.id === BANK_SLIP_ID)
              .map((b) => b?.review_status)[0] ? (
              <StatusTags status="verified" />
            ) : (
              <StatusTags status="uploaded" />
            )}
          </div>
        ),
      },
      {
        Header: "Gender",
        accessor: (c) => c?.gender?.name,
      },
      {
        Header: "Nationality",
        accessor: (c) => (
          <span className="bg-blue-200 text-blue-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-blue-900 dark:text-blue-200">
            {c?.nationality?.name}
          </span>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ cell }) => (
          <button
            key={cell?.row.id}
            className="disabled:opacity-60 disabled:bg-blue-200 disabled:text-black disabled:cursor-not-allowed border-2 border-blue-400 text-blue-400 font-normal px-3 py-1 rounded-md hover:bg-blue-400 hover:text-white"
          >
            {cell?.row?.original?.expatriate_work_permits[0]
              ?.assigned_workpermit_reviewers?.length > 0 && (
              <>
                <div
                  onClick={() =>
                    navigate(
                      "/dashboard/applicant/profile/" + cell?.row?.original?.id
                    )
                  }
                  className="flex items-center gap-2"
                >
                  <BsFillPersonCheckFill className="text-green50 text-lg" />
                  <FiAlertCircle className="text-N80 text-lg" />
                </div>
              </>
            )}
          </button>
        ),
      },
    ];
  },
};
