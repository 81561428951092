import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GetLatestProfilePic } from "../../utils/utilityFunctions";

const ExpatriateEmrCard = ({ data, title, handleClick }) => {
  const router = useNavigate();

  useEffect(() => {
    console.log(data);
  },[]);

  return (
    <div className="border-2 mt-5 bg-white rounded-md">
      <div className="p-5 flex flex-wrap justify-between">
        <div className="flex flex-wrap gap-5">
          {/* {data?.expatriate_work_permit_documents
            .filter((i) => i?.title === "Profile Picture")
            ?.map((i) => ( */}
            <div className="rounded-full flex justify-center items-center w-36 h-36 border">
              <span className="text-2xl font-bold">{data?.expatriate?.first_name?.substring(0,1)}{data?.expatriate?.father_name?.substring(0,1)}</span>
            </div>
            
          <div className="flex flex-wrap gap-5">
            <div className="flex flex-col border-r-2 border-primary/30 pr-5">
              <h2 className="font-bold text-lg">
                {data?.expatriate?.first_name +
                  " " +
                  data?.expatriate?.father_name}
              </h2>
              <small>{data?.expatriate?.nationality?.name}</small>
              <br />
              <span>{data?.expatriate?.visa_type?.name}</span>
              <small>{data?.expatriate?.marital?.name}</small>
              <span className="px-4 py-0.5 w-fit rounded-md bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-100">
                Active
              </span>
            </div>
            <div className="flex flex-col">
              <div>
                <span className="text-gray-500">Passport Number</span>
                <h2>{data?.expatriate?.passport_number}</h2>
              </div>
              <br />
              <div>
                <span className="text-gray-500">Visa Number</span>
                <h2>{data?.expatriate?.visa_number}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="self-center">
          <div className="flex flex-col gap-4">
            <button
              type="button"
              onClick={() => handleClick(data?.expatriate?.id)}
              className="font-normal border-2 rounded-md px-4 py-2 hover:bg-primary hover:text-white ease-in-out"
            >
              {title && title}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ExpatriateEmrCard.defaultProps = {
  title: "Renewal",
  handleClick: () => {},
  data: {},
};

export default ExpatriateEmrCard;
